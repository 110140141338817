<template>
  <v-container fluid   style="margin-top:60px;margin-bottom: 100px;" >

    <v-row>
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;justify-content: center;">
          <span style="font-size:30px;">{{ points }}</span><br>
            <v-img src="img/ico_points.png" style="max-width: 51px;margin-left: 10px;"></v-img>
          </v-col>
       
  </v-row>


  <v-row style="    margin-top: -25px;">
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;justify-content: center;">
          <span style="font-size:19px;">Доступно балів</span>
          </v-col>
       
  </v-row>

  <v-row>
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;">
          <span style="font-size:19px;">Зароблено: {{ total_points }}</span><br>
            <v-img src="img/ico_points.png" style="max-width: 34px;margin-left: 10px;"></v-img>
          </v-col>
       
  </v-row>

  
  
  <v-row style="margin-bottom: 10px;margin-top:-15px;">
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;">
          <span style="font-size:19px;">Витрачено: {{ retr_points }}</span><br>
            <v-img src="img/ico_points.png" style="max-width: 34px;margin-left: 10px;"></v-img>
          </v-col>
       
  </v-row>

  

  
  <v-row style="margin-bottom: 10px;margin-top:-15px;">
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;">
          <span style="font-size:19px;">Згорить 01.02.2025: {{ balance_burn }}</span><br>
            <v-img src="img/ico_points.png" style="max-width: 34px;margin-left: 10px;"></v-img>
          </v-col>
       
  </v-row> 

  <v-card class="simple_card">
    <div style="display:flex;justify-content: space-between; padding: 15px 15px 5px 15px;"> 
      <div>Період: </div> 
      <div>{{detail_h}} </div>
      <div class="calendar" @click="calendar_show=true"></div>
    </div>
    <hr style="margin-left: 13px;
    margin-right: 13px;">
  </v-card>


  <v-dialog v-model="calendar_show" style="">
      <v-card class="simple_card">
        <v-card-title class="headline lighten-2" style="margin-bottom:-10px;justify-content:center;">
          <!-- header -->
          Обери період  
          <div @click="reset_pass_dialog = false" class="calendar_show"></div>
        </v-card-title>
       
        <v-card-text>
          <v-row  fluid align="center"
        justify="center">
      <v-col cols="12" align="center"
        justify="center">
    <v-date-picker
      
        v-model="date"
        :allowed-dates="allowed_dates_m"
        type="month" 
        class="mt-0 no-title"
        :min="pars_min_date"
        :max="pars_max_date"
         :month-format="getMonthFormat"
        :title-date-format="getTitleDateFormat"
        color="orange lighten-1"
      ></v-date-picker>
      </v-col>
    </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="calendar_show = false">   Закрити</span>
        </v-card-actions> -->
        
      </v-card>
     <div class="popup_bottom_robot"></div>
    </v-dialog>

  <v-card style="margin-top: 25px;;">
        <!-- <v-card-title class="headline grey lighten-2">
         
          {{detail_h}}  
        </v-card-title>
        -->
        <v-card-text>
          <v-row fluid style="border-bottom: 1px solid grey;/* Бонус */


font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 109% */
text-align: center;

color: #4B3C32;

">
            <v-col cols="5" style="border-right: 1px solid grey;">
              <span><center>{{$t('h_descr')}}</center></span>
              
            </v-col>
            <v-col cols="3" style="border-right: 1px solid grey;"><center>{{$t('h_amount')}}</center></v-col>
            <v-col cols="4">{{$t('h_total')}}</v-col>
          </v-row>
          <v-row fluid v-for="(op, i) in own_list_history" :key="i" style="padding-bottom: 5px;">
            <v-col cols="5"  style="border-right: 1px solid grey;">
              <v-row><span style="
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 19px;
/* or 53% */

color: #4B3C32;">{{op['descr']}}</span></v-row>
              <v-row><span style="font-family: 'Montserrat';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 17px;
/* or 121% */

color: #4B3C32;">{{op['date']}}</span></v-row>
            </v-col>
            <v-col cols="3" style="border-right: 1px solid grey;" v-bind:class ="op['amount']<0?'gr_b':'or_b'"><center><span v-if="op['amount']>0">+</span>{{op['amount']}}</center></v-col>
            <v-col cols="4">{{op['total']}}</v-col>
          </v-row>
          
        </v-card-text>
        <v-divider></v-divider>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="detail = false">   {{$t('close')}} </span>
        </v-card-actions> -->
      </v-card>

    <!-- <v-card class="rounded-lg" elevation="10" style="position:fixed;top:55px;left:0;right:0; margin:auto;z-index:150;width:60%;">
          
          <v-card-text style="background-color:#fdaa47;color:white;text-align:center;">
             <span style="font-size:40px;padding:30px;">{{ points }}</span><br>
             <span>Доступно балів </span><br><br>
              <span style="font-size:20px;padding:10px;">{{ total_points }} / {{ retr_points }}</span><br>
             <span>зароблено / витрачено </span>
        
         </v-card-text>

      
        
      </v-card>  -->
   

    <!-- <v-row  fluid align="center"
        justify="center">
      <v-col cols="12" align="center"
        justify="center">
    <v-date-picker
      
        v-model="date"
        :allowed-dates="allowed_dates_m"
        type="month" 
        class="mt-4"
        :min="pars_min_date"
        :max="pars_max_date"
         :month-format="getMonthFormat"
        :title-date-format="getTitleDateFormat"
        color="orange lighten-1"
      ></v-date-picker>
      </v-col>
    </v-row> -->
     
    <!-- <v-row fluid v-for="(button, i) in own_list" :key="i">
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <btnrnd
            img="img/m_ico_pig.png"
            @click.native="disp_history(button['year'],button['month'])"
            >{{month_lang[$i18n.locale][button['month']-1]}} {{button['year']}}</btnrnd
          ></v-col
        >
        <v-col cols="1"></v-col>
      </v-row> -->




<!-- 
      <v-row fluid>
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <v-btn rounded class="primary_button" @click.native="$router.push({ name: 'Withdraw_sel' })">{{$t('btn_change')}}</v-btn>
        
        </v-col>
        <v-col cols="1"></v-col>
      </v-row> -->

   
     <v-dialog v-model="detail"  style="z-index:1100;">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <!-- header -->
          {{detail_h}}  
        </v-card-title>
       
        <v-card-text>
          <v-row fluid>
            <v-col cols="4">
              <span><b><center>{{$t('h_descr')}}</center></b></span>
              
            </v-col>
            <v-col cols="5"><b><center>{{$t('h_amount')}}</center></b></v-col>
            <v-col cols="3"><b>{{$t('h_total')}}</b></v-col>
          </v-row>
          <v-row fluid v-for="(op, i) in own_list_history" :key="i">
            <v-col cols="4">
              <v-row><span><b>{{op['descr']}}</b></span></v-row>
              <v-row><span style="font-size:0.6rem">{{op['date']}}</span></v-row>
            </v-col>
            <v-col cols="5"><center>{{op['amount']}}</center></v-col>
            <v-col cols="3">{{op['total']}}</v-col>
          </v-row>
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="detail = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    calendar_show:false,
    date: '',
    allowed_dates_arr:[],
    pars_min_date:'',
    pars_max_date:'',
    total_points:0,
    retr_points:0,
    balance_burn:0,
    detail:false,
    detail_h:'',
    month_lang:{
        
        'ua':["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"]
      },
    own_list:[],
    own_list_history:[],
    
    
  }), 
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },
  },
  watch:{
      date:function(){
        this.calendar_show=false
          console.log("sel_date:"+this.date);
          this.disp_history(this.date.split('-')[0],this.date.split('-')[1]);
      },

      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
    axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "get_balance_month",
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        console.log(resp.data);
        var counter=0;
        resp.data.forEach((element) => {
          this.allowed_dates_arr.push( element.year+'-'+element.month)
          counter++;
          if(counter===1){ this.pars_max_date=element.year+'-'+element.month; } else {this.pars_min_date=element.year+'-'+element.month}
          
          this.own_list.push({
            month: element.month,
            year: element.year,
            
          });

          
        });
        console.log(this.allowed_dates_arr);
        
        
      });

      axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "get_balance_descr",
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        this.total_points=resp.data.total_points;
        this.retr_points=resp.data.total_retr;
        this.balance_burn=resp.data.balance_burn;
        console.log(resp.data);
        
        
        
      });

      this.disp_history(2024,10);


  },
  methods:{

    getYearsRange(start, end) {
      const years = [];
      for (let year = start; year <= end; year++) {
        years.push(year);
      }
      return years;
    }
  ,

    getMonthFormat: function(isoDate) {
      var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    }, 
    getTitleDateFormat: function(isoDate) {
       var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
       
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    },

    allowed_dates_m: function(val){
        return this.allowed_dates_arr.includes(val)
    }
    ,

    disp_history: function(year,month){
      axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "get_balance_details",
          year: year,
          month: month,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        console.log(resp.data);
        this.own_list_history=[]
        //this.detail=true;
        this.detail_h=this.month_lang[this.$i18n.locale][month-1]+"  "+year;
        resp.data.forEach((element) => {
          this.own_list_history.push({
            descr: element.description,
            amount: element.operation_amount,
            date: element.operation_date,
            total: element.balance            
          });
          
        });
        console.log(this.own_list_history);
        
        
      });
    }
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
  },
};
</script>

<i18n>
{
  "ge":{
      "title":"ჩემი ქულები",
      "close":"დახურვა",
      "hist":"დაგროვილი ქულების ისტორია ",
      "btn_change":"ქულების გადაცვლა ",
      "h_descr": "აღწერა",
      "h_amount": "დარიცხული",
      "h_total": "სულ"
  },
  "ua":{
      "title":"Мої бали",
      "close":"Закрити",
      "hist":"Історія:",
      "btn_change":"Обміняти",
      "h_descr":"Опис",
      "h_amount":"Бонус",
      "h_total":"Баланс"

      

  }
}
</i18n>

<style scoped>
.v-date-picker-title__year{
  display: unset !important;
  text-align: center !important;
}

.v-date-picker-title,.v-picker__title__btn{
  display: unset !important;
  text-align: center !important;
}

.or_b{
  color: #F07300;
}

.gr_b{
  color: #4B3C32;
}

.calendar{
  background-image: url(/img/calico.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}
::v-deep .no-title .v-picker__title {
  display: none; /* Hides the title */
}
</style>